import styled from "@emotion/styled"

export const Card = styled(`div`)`
  background: ${p => p.theme.colors.white};
  /* Elevated */
  box-shadow: 0px 1px 2px rgba(46, 41, 51, 0.08),
    0px 2px 4px rgba(71, 63, 79, 0.08);
  border-radius: ${p => p.theme.space[2]};
  padding: ${p => p.theme.space[5]};
`
