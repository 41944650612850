import * as React from "react"
import { ThemeCss } from "gatsby-interface"
import { StandardSingleColumn } from "@modules/ui/layouts/Containers"
import { FixedFooterWithLip } from "@modules/ui/components/FixedFooterWithLip"

const footerCss: ThemeCss = theme => ({
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  background: theme.colors.white,
})

const buttonsContainerCss: ThemeCss = theme => ({
  paddingTop: theme.space[8],
  paddingBottom: theme.space[8],
  display: `flex`,
  justifyContent: `space-between`,
  alignItems: `flex-start`,
})

export type WizardFooterProps = {
  goBackButton: React.ReactNode
  goNextButton?: React.ReactNode
  children?: React.ReactNode
}

export function WizardFooter({
  children,
  goBackButton,
  goNextButton,
}: WizardFooterProps) {
  return (
    <FixedFooterWithLip css={footerCss}>
      <StandardSingleColumn css={{ marginTop: 0, marginBottom: 0 }}>
        {children}
        <div css={buttonsContainerCss}>
          {goBackButton}
          {goNextButton}
        </div>
      </StandardSingleColumn>
    </FixedFooterWithLip>
  )
}
