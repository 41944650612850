import * as React from "react"
import { RouteComponentProps } from "@gatsbyjs/reach-router"
import {
  useAriaFormGroupField,
  FormFieldset,
  Heading,
  ThemeCss,
  Button,
  EmptyState,
  EmptyStatePrimaryAction,
  Spacer,
} from "gatsby-interface"
import Background from "@modules/ui/components/CreateSiteBackground"
import { visuallyHiddenCss } from "@modules/a11y/stylesheets"
import {
  createSite as createSiteText,
  ui as uiText,
} from "@modules/locales/default.js"
import { StyledForm } from "@modules/ui/components/Primitives"
import {
  RadioButtonCard,
  RadioButtonCardInput,
} from "@modules/ui/components/RadioButtonCard"
import { SiteCreationWizardHeader } from "@modules/site/create/shared/components/SiteCreationWizardHeader"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { navigate } from "gatsby"
import {
  getPathToOrgDetails,
  getPathToOrgDetailsTab,
} from "@modules/organization/shared/utils"
import { MdArrowForward } from "react-icons/md"
import { useTracker } from "@modules/analytics"
import { recordEvent, FullStoryEvent } from "@modules/fullStory"
import {
  getPathToProvisionSite,
  getPathToImportSite,
} from "@modules/site/create/shared/utils"
import {
  siteTypes,
  SiteCreationType,
  isConnectedToSourceProvider,
} from "@modules/site/create/ShopifyApp/shared/ShopifyAppSiteCreationTypePicker.helpers"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"
import { useOrganizationPermissionsInfoQuery } from "@modules/organization/queries.generated"
import { OrgDetailsTab } from "@modules/organization/details/constants"

const formCss: ThemeCss = theme => ({
  marginTop: theme.space[15],
})

const positioningCss: ThemeCss = theme => ({
  maxWidth: `90%`,
  margin: `0 auto ${theme.space[10]}`,

  [theme.mediaQueries.desktop]: {
    maxWidth: `60rem`,
  },
})

const selectorCss: ThemeCss = theme => [
  positioningCss(theme),
  {
    display: `flex`,
    alignItems: `stretch`,
    flexDirection: `column`,

    [theme.mediaQueries.desktop]: {
      alignItems: `center`,
      flexDirection: `row`,
    },
  },
]

const optionCardCss: ThemeCss = theme => ({
  flexGrow: 1,
  flexBasis: `100%`,

  [theme.mediaQueries.desktop]: {
    ":not(:first-of-type)": {
      marginLeft: theme.space[8],
    },
  },
})

const cardSpacingCss: ThemeCss = theme => ({
  marginTop: theme.space[4],
  marginBottom: theme.space[4],
})

export type ShopifyAppSiteCreationTypePickerProps = RouteComponentProps<{
  organizationId: string
  frameable?: boolean
}>

export function ShopifyAppSiteCreationTypePicker({
  organizationId,
  frameable = false,
}: ShopifyAppSiteCreationTypePickerProps) {
  const { data: sourceProviders } = useAvailableSourceProvidersQuery()
  const [siteType, setSiteType] = React.useState<SiteCreationType>(
    SiteCreationType.Import
  )
  const { data, loading } = useOrganizationPermissionsInfoQuery({
    variables: { id: organizationId },
    skip: !organizationId,
  })
  const {
    getLegendProps,
    getOptionControlProps,
    getOptionLabelProps,
  } = useAriaFormGroupField("siteType", { required: true })
  const { trackButtonClicked } = useTracker()

  if (!organizationId || !sourceProviders || loading) {
    return null
  }

  const hasAccess = Boolean(
    data?.organizationDetails?.permissions?.sites?.create
  )

  if (!hasAccess) {
    return (
      <main>
        <SiteCreationWizardHeader organizationId={organizationId} />
        <Spacer size={10} />
        <div css={positioningCss}>
          <EmptyState
            heading={createSiteText.headers.noAccessTitle}
            headingAs="h2"
            text={createSiteText.messages.noAccessDescription}
            primaryAction={
              <EmptyStatePrimaryAction
                to={getPathToOrgDetailsTab(organizationId, OrgDetailsTab.Sites)}
              >
                {createSiteText.actions.backToWorkspace}
              </EmptyStatePrimaryAction>
            }
            variant="BORDERED"
          />
        </div>
      </main>
    )
  }

  return (
    <main>
      <SiteCreationWizardHeader organizationId={organizationId} />
      <Background
        css={{
          position: `absolute`,
          left: 0,
          top: 0,
          zIndex: -10,
          maxWidth: "100%",
        }}
      />
      <StyledForm
        css={formCss}
        onSubmit={e => {
          e.preventDefault()

          const isProvisionType = siteType === SiteCreationType.Provision

          trackButtonClicked(isProvisionType ? `provision` : `create`, {
            uiSource: `Site Type Selection`,
          })

          recordEvent(
            isProvisionType
              ? FullStoryEvent.AutoprovisionNewSite
              : FullStoryEvent.UseExistingSite
          )

          if (isProvisionType) {
            return navigate(`${getPathToProvisionSite(organizationId)}/cms`)
          }

          return navigate(
            `${getPathToImportSite(
              organizationId,
              frameable ? `site-create` : undefined
            )}/repository`
          )
        }}
      >
        <FormFieldset>
          <legend
            css={visuallyHiddenCss}
            {...getLegendProps(createSiteText.labels.pickSiteType)}
          />
          <div css={selectorCss}>
            {siteTypes
              .filter(siteType =>
                frameable ? siteType.value !== SiteCreationType.Provision : true
              )
              .map(({ label, value: optionValue, Description, providers }) => {
                const selected = siteType === optionValue
                const descriptionId = `siteType__description--${optionValue}`
                const isConnected = isConnectedToSourceProvider(
                  sourceProviders?.availableSourceProviders || [],
                  providers
                )

                return (
                  <RadioButtonCard
                    key={`siteType_${optionValue}`}
                    checked={selected}
                    css={theme => [
                      optionCardCss(theme),
                      !selected && cardSpacingCss(theme),
                    ]}
                    disabled={!isConnected}
                  >
                    <div
                      css={theme => [
                        {
                          display: `grid`,
                          gridTemplateColumns: `max-content auto`,
                          gridGap: theme.space[5],
                          alignItems: `center`,
                        },
                        selected && cardSpacingCss(theme),
                      ]}
                    >
                      <RadioButtonCardInput
                        value={optionValue}
                        name="siteType"
                        checked={selected}
                        {...getOptionControlProps(optionValue)}
                        onChange={() => {
                          setSiteType(optionValue)
                        }}
                        aria-describedby={descriptionId}
                      />
                      <label
                        {...getOptionLabelProps(optionValue)}
                        css={{ cursor: `pointer` }}
                      >
                        <Heading
                          as="span"
                          css={theme => ({
                            fontSize: theme.fontSizes[5],
                            color: selected ? theme.colors.purple[60] : null,
                          })}
                        >
                          {label}
                        </Heading>
                      </label>
                      <div />
                      <div
                        id={descriptionId}
                        css={theme => ({
                          fontSize: theme.fontSizes[2],
                          color: theme.colors.grey[60],
                        })}
                      >
                        <Description available={isConnected} />
                      </div>
                    </div>
                  </RadioButtonCard>
                )
              })}
          </div>
        </FormFieldset>
        <WizardFooter
          goBackButton={
            <Button
              variant="SECONDARY"
              onClick={() => {
                if (frameable) {
                  navigate(-1)
                } else {
                  navigate(getPathToOrgDetails(organizationId))
                }
              }}
            >
              {uiText.actions.cancel}
            </Button>
          }
          goNextButton={
            <Button
              variant="PRIMARY"
              type="submit"
              rightIcon={<MdArrowForward />}
            >
              {uiText.actions.next}
            </Button>
          }
        />
      </StyledForm>
    </main>
  )
}
