import React from "react"

import PropTypes from "prop-types"
import { CheckIcon, hexToRGBA, visuallyHiddenCss } from "gatsby-interface"
import { Card } from "@modules/ui/components/LegacyCard"

const baseCss = theme => ({
  margin: "0",
  padding: theme.space[8],
  boxSizing: "border-box",
  border: "1px solid transparent",
  borderRadius: theme.radii[4],
  position: "relative",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
})

const selectedCss = theme => ({
  borderColor: theme.colors.gatsby,
  boxShadow: `0px 2px 4px ${hexToRGBA(theme.colors.gatsby, `0.08`, true)},
    0px 4px 8px ${hexToRGBA(theme.colors.gatsby, `0.16`, true)}`,
})

const RADIO_SIZE = 24

const inputHoveredMixin = theme => ({
  "& + span": {
    backgroundColor: theme.colors.purple[40],
    borderColor: theme.colors.purple[40],
  },
})

const inputCss = theme => ({
  opacity: 0,
  position: `absolute`,
  width: RADIO_SIZE,
  height: RADIO_SIZE,
  margin: 0,
  "& + span": {
    alignItems: "center",
    display: "flex",
    width: RADIO_SIZE,
    height: RADIO_SIZE,
    background: theme.colors.white,
    border: `1px solid ${theme.colors.ui.border.subtle}`,
    boxSizing: "border-box",
    borderRadius: theme.radii[6],
    color: theme.colors.white,
    justifyContent: "center",
  },
  "&:checked, &:not(:disabled):hover": {
    "& + span": {
      backgroundColor: theme.colors.gatsby,
      borderColor: theme.colors.gatsby,
    },
  },
  "&:not(:disabled):hover": inputHoveredMixin(theme),
  "&:disabled": {
    "& + span": {
      display: `none`,
    },
  },
  "&:focus": {
    "& + span": {
      outline: [`1px dotted #212121`, `5px auto -webkit-focus-ring-color`],
    },
  },
})

const RadioButtonCardContext = React.createContext({
  checked: false,
  highlighted: false,
  disabled: false,
  hovered: false,
  setHovered: () => undefined,
  setInputRef: () => undefined,
})

export function useRadioButtonCardContext() {
  return React.useContext(RadioButtonCardContext)
}

export function RadioButtonCard({
  checked,
  disabled,
  children,
  className,
  "data-cy": dataCy,
}) {
  const [hovered, setHovered] = React.useState(false)
  const highlighted = !disabled && (hovered || checked)
  const inputRef = React.useRef(null)

  const contextValue = {
    checked,
    highlighted,
    hovered,
    disabled,
    setHovered,
    setInputRef: element => {
      inputRef.current = element
    },
  }

  return (
    <RadioButtonCardContext.Provider value={contextValue}>
      <Card
        css={theme => [
          baseCss(theme),
          disabled && {
            background: theme.colors.grey[10],
            cursor: `not-allowed`,
          },
          checked && selectedCss(theme),
        ]}
        className={className}
        onClick={() => {
          if (disabled) {
            return
          }

          if (inputRef.current) {
            inputRef.current.focus()
            inputRef.current.click()
          }
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        data-cy={dataCy}
      >
        {children}
      </Card>
    </RadioButtonCardContext.Provider>
  )
}

RadioButtonCard.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  "data-cy": PropTypes.string,
  isHidden: PropTypes.bool,
}

export function RadioButtonCardInput({
  InputComponent = "input",
  isHidden = false,
  ...rest
}) {
  const {
    disabled,
    hovered,
    setHovered,
    setInputRef,
  } = useRadioButtonCardContext()

  return (
    <div css={theme => [isHidden && visuallyHiddenCss]}>
      <InputComponent
        css={theme => [
          inputCss(theme),
          !disabled && hovered && inputHoveredMixin(theme),
        ]}
        type="radio"
        {...rest}
        disabled={disabled}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        onChange={e => {
          rest.onChange && rest.onChange(e)
        }}
        ref={setInputRef}
      />

      <span>
        <CheckIcon />
      </span>
    </div>
  )
}
